<template>
  <div id="userpassword" class="boxsty login">
    <i class="el-icon-back back-icon" @click="tologin" v-show="$store.state.userlogin == 2"></i>
    <div class="title">
      <div class="text active" v-if="$store.state.userlogin == 2">重置密码</div>
      <div class="text active" v-if="$store.state.userlogin == 3">欢迎注册</div>
    </div>

    <div class="inputbox">
      <i class="iconfont icon-yonghu icon-size" style="font-size: 20px"></i>
      <div class="label">中国 +86</div>
      <el-input class="input inputlabel" type="text" v-model="phone" autocomplete="off" placeholder="请输入手机号" />
    </div>
    <div class="inputbox">
      <i class="iconfont icon-yanzhengma icon-size"></i>
      <el-input class="input" type="text" v-model="code" autocomplete="new-password" placeholder="输入验证码" />
      <div class="codebox" v-if="!codeshow" @click="getcode">获取验证码</div>
      <div class="codebox" style="color: #3773da" v-else>
        {{ codetime }}秒后获取
      </div>
    </div>
    <div class="inputbox">
      <i class="iconfont icon-mimaxiugai icon-size"></i>
      <el-input class="input" type="password" v-model="password" autocomplete="new-password"
        placeholder="设置密码（8-20个字，包含字母和数字）" show-password />
    </div>
    <div class="logininfo">
      <div class="chekbox" v-if="$store.state.userlogin == 3">
        <el-checkbox v-model="checked"></el-checkbox>
        我已同意
        <!-- <span>《用户使用协议》</span> -->
        <router-link to="/agreement" target="_blank">《用户使用协议》</router-link>
      </div>
    </div>
    <div class="loginbtn" v-if="$store.state.userlogin == 2" @click="modify">
      确认修改
    </div>
    <div class="loginbtn" v-if="$store.state.userlogin == 3" @click="register">
      立即注册
    </div>
    <div class="backlogin" v-show="$store.state.userlogin == 3">
      已有账号?
      <span @click="tologin">登录</span>
    </div>
  </div>
</template>

<script>
  import {
    eltips
  } from "@/util/util.js";
  export default {
    name: "password",
    data() {
      return {
        phone: "",
        password: "",
        code: "",
        codeshow: false,
        codetime: 59,
        userlogin: "",
        checked: false,
      };
    },
    created() {
      this.userlogin = this.$store.state.userlogin;
    },
    methods: {
      //返回登录
      tologin() {
        // this.$store.commit("changeuserlogin", 1);
        this.$emit("handleBackRegister", 1, true, 1);
      },
      //获取验证码
      getcode() {
        let that = this;
        this.axios.post(
          "/api/send_code", {
            phone: this.phone,
          }, {
            header: {
              "Content-Type": "multipart/form-data",
            },
          }
        ).then((res) => {
          console.log(res);
          if (res.code == 200) {
            eltips(res.msg, "success");
            that.codeshow = true;
            let autocode = setInterval(() => {
              if (that.codetime > 1) {
                that.codetime--;
              } else {
                that.codeshow = false;
                that.codetime = 59;
                clearInterval(autocode);
              }
            }, 1000);
          } else {
            eltips(res.msg, "error");
          }
        }).catch((error) => {
          console.log(error);
        });
      },
      //注册
      register() {
        if (this.checked) {
          this.axios.post(
            "/api/register", {
              unitid: "",
              phone: this.phone,
              sms_code: this.code,
              password: this.password,
              password_confirmation: this.password,
            }, {
              header: {
                "Content-Type": "multipart/form-data",
              },
            }
          ).then((res) => {
            console.log(res);
            if (res.code == 200) {
              this.$message(res.msg);
              this.$store.commit("changeuserlogin", 1);
            } else {
              this.$message(res.msg);
            }
          }).catch((error) => {
            console.log(error);
          });
        } else {
          this.$message("请阅读用户使用协议");
        }
      },
      //	修改密码
      modify() {
        this.axios.post(
          "/api/retrieve", {
            phone: this.phone,
            sms_code: this.code,
            new_password: this.password,
            new_password_confirmation: this.password,
          }, {
            header: {
              "Content-Type": "multipart/form-data",
            },
          }
        ).then((res) => {
          console.log(res);
          if (res.code == 200) {
            this.$message(res.msg);
            this.$store.commit("changeuserlogin", 1);
          } else {
            this.$message(res.msg);
          }
        }).catch((error) => {
          console.log(error);
        });
      },
    },
  };
</script>

<style>
  #userpassword .el-input__inner {
    border: none !important;
    height: 36px !important;
    line-height: 36px !important;
    padding-left: 16px !important;
    border-radius: 0 !important;
    background: transparent;
    box-sizing: border-box;
    border-left: 1px solid #e3e9f3 !important;
  }

  /* #userpassword .el-input__inner:focus {
  border-color: #3773da !important;
} */
</style>

<style lang="less" scoped>
  ::v-deep .el-input__inner::placeholder {
    font-size: 14px;
  }

  .login {
    padding: 35px 54px;
    box-sizing: border-box;

    .title {
      text-align: center;

      .text {
        color: #333;
        font-weight: 600;
        font-size: 28px;
      }

      .active {
        font-size: 26px;
        color: #323233;
      }
    }

    .inputpad {
      padding-top: 0;
    }

    .inputbox {
      margin-top: 30px;
      position: relative;
      background: #f9fbff;
      border: 1px solid #ecf0f8;
      height: 60px;
      display: flex;
      align-items: center;
      padding-left: 20px;
      box-sizing: border-box;
      border-radius: 6px;

      .label {
        font-size: 16px;
        line-height: 48px;
        display: inline-block;
        margin-right: 10px;
      }

      .input {
        font-size: 16px;
        width: 100%;
        height: 48px;
        line-height: 47px;
        box-sizing: border-box;
        border: none;
      }

      .codebox {
        line-height: 48px;
        position: absolute;
        right: 20px;
        top: 50%;
        font-size: 16px;
        transform: translate(0, -50%);
        color: #999;
      }

      .inputlabel {
        display: inline-block;
        width: 220px;
        float: right;
        flex: 0 0 220px;
      }

      .input:focus {
        border-color: #3773da;
      }
    }

    .loginbtn {
      height: 60px;
      border-radius: 6px;
      font-weight: 600;
      line-height: 60px;
      text-align: center;
      font-size: 18px;
      color: #fff;
      background: #479cf7;
      border-radius: 5px;

      &:hover {
        background: #3773da;
        cursor: pointer;
      }
    }

    .logininfo {
      overflow: hidden;
      font-size: 14px;
      margin: 41px 0 16px;

      .chekbox {
        float: left;

        span {
          color: #479cf7;
          cursor: pointer;
        }
      }
    }
  }

  .icon-size {
    font-size: 25px;
    color: #abb5c9;
    margin-right: 10px;
  }

  .backlogin {
    text-align: center;
    margin-top: 22px;

    span {
      display: inline-block;
      font-size: 14px;
      margin-left: 10px;
      color: #479cf7;
      cursor: pointer;
    }
  }

  .back-icon {
    font-size: 20px;
    position: absolute;
    cursor: pointer;
  }
</style>