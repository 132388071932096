<template>
  <div class="login-box" :style="bgStyle">
    <div class="login-content">
      <div class="left-text">
        <div class="left-text-top">高效、专业的全渠道SaaS服务商平台</div>
        <div class="left-text-bottom">
          多场景灵活应用，持续稳定迭代更新，个性化定制专属品牌
        </div>
      </div>
      <div class="box">
        <img :src="checkLoginType? loginPwd: loginQr" v-show="$store.state.userlogin == 1" class="checkimg"
          @click="handleLoginType" />
        <div v-show="checkLoginType">
          <userlogin v-if="$store.state.userlogin == 1" :logintypes="logintypes"></userlogin>
          <password v-if="$store.state.userlogin != 1" @handleBackRegister="handleBackRegister"></password>
        </div>
        <div v-show="!checkLoginType && $store.state.userlogin==3">
          <qrCodeLogin @handleBackRegister="handleBackRegister" />
        </div>
      </div>
    </div>

    <div class="copyright" v-html="copyright"></div>
  </div>
</template>

<script>
  import userlogin from "../../components/login/login.vue";
  import password from "../../components/login/password.vue";
  import qrCodeLogin from "../../components/login/qrcode-login.vue"
  export default {
    components: {
      userlogin,
      password,
      qrCodeLogin
    },
    name: "login",
    data() {
      return {
        phone: "",
        password: "",
        code: "",
        logintypes: 1,
        codeshow: false,
        codetime: 59,
        case_images: [],
        bgStyle: {
          backgroundImage: 'url("https://yt.hncaixiaowu.com/global/image/2022/08/fb9896d2db24963ae24877a71034d948.jpg")',
          backgroundSize: "cover !important",
          backgroundPosition: "center center !important",
        },
        loginPwd: require("@/assets/icon/new-login-qrcode.png"),
        loginQr: require('@/assets/icon/new-login-account.png'),
        checkLoginType: true,
        copyright: ""
      };
    },
    created() {
      if(location.origin=='https://a.sid1688.cn'){
        this.copyright = `海南微校通网络科技有限公司 &copy; 2015-2023 琼ICP备19004271号`
      }else{
        this.copyright = `四川线上客信息技术开发有限公司 &copy; 2015-2023 <a href="https://beian.miit.gov.cn" style="color: #999">蜀ICP备19013746号</a>`
      }
    },
    methods: {
      handleLoginType() {
        this.checkLoginType = !this.checkLoginType
        if (!this.checkLoginType) {
          this.$store.commit("changeuserlogin", 3);
        }
      },
      handleBackRegister(num, tag, type) {
        console.log(num, tag)
        this.checkLoginType = tag;
        this.logintypes = type
        this.$store.commit("changeuserlogin", num);
      },
    },
  };
</script>

<style scoped lang="less">
  .box {
    width: 468px;
    height: 670px;
    background-color: #fff;
    margin-right: 20px;
    border-radius: 20px;
    position: relative;

    // position: fixed;
    // left: 50%;
    // top: 50%;
    // margin-top: -260px;
    // margin-left: -530px;
    // transform: translate(-50%, -50%);
    .checkimg {
      position: absolute;
      right: 0;
      top: 0;
      cursor: pointer;
    }

    .boxsty {
      // vertical-align: top;
      // display: inline-block;
      // width: 520px;
      // height: 670px;
      // background-color: #fff;
      // margin-right: 20px;
      // padding: 56px;
      // border-radius: 20px;

      &:last-child {
        margin-right: 0;
      }
    }

    .login {
      .title {
        text-align: center;
        margin-bottom: 30px;

        .text {
          display: inline-block;
          vertical-align: bottom;
          margin-right: 40px;
          font-size: 20px;
          color: #969799;
        }

        .active {
          font-size: 26px;
          color: #323233;
        }
      }

      .inputpad {
        padding-top: 30px;
      }

      .inputbox {
        margin-top: 30px;
        position: relative;

        .label {
          font-size: 16px;
          line-height: 48px;
          border-bottom: 1px solid #dcdee0;
          display: inline-block;
        }

        .input {
          font-size: 16px;
          width: 100%;
          height: 48px;
          line-height: 48px;
          box-sizing: border-box;
          border: none;
          border-bottom: 1px solid #dcdee0;
        }

        .codebox {
          line-height: 48px;
          position: absolute;
          right: 0;
          top: 0;
          font-size: 16px;
          color: #999;
        }

        .inputlabel {
          display: inline-block;
          width: 320px;
          float: right;
        }

        .input:focus {
          border-color: #3773da;
        }
      }

      .loginbtn {
        margin-top: 88px;
        line-height: 48px;
        text-align: center;
        font-size: 14px;
        color: #fff;
        background: #155bd4;
        border-radius: 5px;

        &:hover {
          background: #3773da;
          cursor: pointer;
        }
      }

      .logininfo {
        overflow: hidden;
        margin-top: 24px;
        font-size: 14px;
        line-height: 14px;

        .chekbox {
          float: left;

          span {
            color: #155bd4;
          }
        }

        .changbox {
          float: right;
          font-size: 14px;

          .text {
            color: #155bd4;
            padding-right: 10px;
            line-height: 14px;
            display: inline-block;

            &:last-child {
              position: relative;
              padding-right: 0;
              padding-left: 10px;
            }

            &:last-child::after {
              content: "";
              width: 1px;
              height: 14px;
              position: absolute;
              left: 0;
              top: 0;
              background: #ebedf0;
            }

            &:hover {
              cursor: pointer;
            }
          }
        }
      }
    }

    .exp {
      position: relative;

      .title {
        font-size: 26px;
        font-weight: 500;
        color: #323233;
        text-align: center;
      }

      .text {
        line-height: 28px;
        margin: 8px 0 20px;
        font-size: 18px;
        color: #323233;
        text-align: center;
      }

      .link {
        a {
          display: flex;
          -ms-flex-align: center;
          align-items: center;
          -ms-flex-pack: center;
          justify-content: center;
          width: 120px;
          margin: 0 auto;
          font-size: 14px;
          color: #155bd4;
        }
      }

      .imgbox {
        width: 408px;
        position: absolute;
        left: 56px;
        top: 185px;

        .img {
          text-align: center;
          width: 25%;
          display: inline-block;
          margin-top: 20px;

          img {
            width: 52px;
            height: 52px;
            box-sizing: border-box;
            display: inline-block;
            border-radius: 50%;
            box-shadow: 0px 1px 4px 4px rgba(0, 0, 0, 0.2);
          }
        }
      }
    }
  }

  .copyright {
    position: fixed;
    left: 0;
    bottom: 40px;
    width: 100%;
    text-align: center;
    color: #999;
    font-size: 14px;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .login-box {
    width: 100vw;
    height: 100vh;
    position: relative;
  }

  .login-content {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;

    .left-text {
      width: 594px;
      height: 585px;
      border-radius: 20px 0 0 20px;
      padding: 195px 101px 66px 53px;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      // display: -webkit-box;
      // display: -ms-flexbox;
      // display: flex;
      // -webkit-box-orient: vertical;
      // -webkit-box-direction: normal;
      // -ms-flex-direction: column;
      // flex-direction: column;
      // justify-content: flex-end;
      background-size: 100% 100%;
    }

    .left-text-top {
      font-size: 40px;
      line-height: 58px;
      color: #fff;
    }

    .left-text-bottom {
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
      color: hsla(0, 0%, 100%, 0.64);
      margin-top: 26px;
    }
  }
</style>